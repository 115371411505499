import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import Harmony from "./Harmony";
import SmileAndSing from "./SmileAndSing";
import SongOfLife from "./SongOfLife";
import "./styles.css";

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Harmony />} />
        <Route exact path="/smileandsing" element={<SmileAndSing />} />
        <Route exact path="/songoflife" element={<SongOfLife />} />
      </Routes>
    </>
  );
};

export default App;
