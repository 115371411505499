import React from "react";
import HeaderFooter from "./HeaderFooter";
import "./styles.css";

function Harmony() {
  return (
    <>
      <div id="harmony" className="container-fluid mx-auto px-0">
        <div className="row mx-0">
          <div className="col-12 px-0">
            <pre className="text-center mb-0">{`${`

~ ~ ~ HARMONY ~ ~ ~


Song 1

* O' FATHER O' MASTER OF ALL *

O' Father O' Master of all,
We are children innocent and small'
Please show us the way' so that we may obey
And attain salvation O' Lord,
O' Father........

{May the world be united and one'
May love prevail and hatred be shun'](2)
{May violence decrease and all live in peace'
With good will may battles be won,}-(2)
May - YOUR message spread through the world'
And bring us all closer to God,
Please show us the way........

{We get lost in the pleasures of life,
We get shaken when troubles arise,}-(2)
{In sorrow and joy be always nearby,
To help us out of our strife,}-(2)
Be Our-strength when we become weak,
YOUR Guidance and Blessings we seek,
Please show us the way........

* * * * *

Song 2

* H - FOR HOPE *

H - for hope, a hope for this world
A - for apostle of peace
R - for realization of God
D - for divine and pure deeds
E - for every human being
V - for virtuous living

Lord  " HARDEV " The Saviour has come, yes........
H........................................................

Love is God, and God is love,
Let us love each other friend,
Hate is the enemy of man, Beware of this fiend,

Ho-nour, Let us honour all,
Fo-llow the true masters call,
Then true happiness will be ours, yes,
H........................................................

Doubts make mountains of mole hills,
Faith can move the mountains dear,

Deeds speak louder than our words,
Courage is stronger than fear,
Mer-cy over powers revenge, Cha-rity is a challenge,
Let us face this challenge to-day, O'Yes........
H........................................................

Life is a journey through time,
We are travellers galore,
The Messiah is a ship,
That can take us to the shore,
Let - us surrender to Him, Shed our fancies & our whims,
" HE" will take us to our destiny, yes,
H........................................................

* * * * *

Song 3

* DON'T WORRY BE HAPPY *

When you're feeling very lonely and sad,
And the world around you seems just too bad,
There is darkness all around, And not a soothing sound,
And you remember the sorrows you have had

Don't worry, be happy........(2)
For the saviour is here,
So now smile with good cheer,
HE will turn to good all that is bad,
Don't worry, be happy........(2)

When you're all alone and haven't any friend,
And your problem seems to never, never end,
You feel lost and repent, And your courage is all spent
And your doomsday seems just around the bend

Don't worry, be happy........(2)
For the Saviour.....................

The future is not in our control,
And the past will never repeat its role,
All we have is the present, Let us value each moment
With our mind, with our body and our soul,

Don't worry, be happy........(2)
For the Saviour.....................

* * * * *

Song 4

* THE PRESENT IS OUR OWN *

The present is our own, future is unknown,
Make the best of to-day dear friend,
We are here for a while, life is very fragile,
It may just break away dear friend.
Value each moment, for the present,
Will not remain so the very next moment,
Act to day and be wise
For to-morrow may not rise,
Ful-fill your cause, and do not pause,
We aren't here to stay dear friend,
We are here for a while............

Life is a treasure, time is a measure,
Make it a joy, let us make it a pleasure
Live happily, in harmony,
Sharing our love, costing no penny
O' children of God, remember the Lord,
Let us not go astray dear friend,
We are here for a while............

All that are human, have in common,
Their duty to attain salvation,
Prophets always, show us the way,
With confidence I surely say,
"HARDEV" the Lord, unites us with God,
"He's the prophet of the day dear friend
We are here for a while............

* * * * *

Song 5

* WHEN TIMES ARE BAD *

When time are bad, and you are sad,
With no one to cheer you, or make you glad
No friends to care, no one to share
All the sufferings that you may have had
My dear friend, that's not the end
There is still hope just around the bend
Shed all you gloom, and let life bloom,
You aren't alone, you still have ONE true FRIEND
Remember, the LORD, is with you,
Now smile, because, "HE" cares for you.

When times are good, in a joyous mood
When you feel you are on top, of the world
With friends galore, success and more
You have everything that you ever could

Do not let pride, take you for a ride
Do not look down upon the, world so wide
Do not forget, all that you get
All you have is a gift GOD, did provide
Remember, the LORD, is with you,
Now smile, because, "HE" cares for you.

Sorrow and joy, keep passing by
Life keeps on moving forward day by day
Never the same, you will remain
To-morrow will not be same, as to-day
Only the LORD the loving God
Will be with you till the end of the world
Come take HIS hand, and firmly stand
And enjoy your life as you really should
Remember, the LORD, is with you,
Now smile, because, "HE" cares for you.

* * * * *

Song 6

* WE ARE ALL HUMAN BEINGS *

We are all human beings,
Let us all live in peace,
Life is but a gift of God,
Let us remember the Lord,
Love everyone, Let us love everyone

Body, mind and the soul,
Are a part of the Whole,
Omnipresent everywhere,
See the Lord, here and there,
Love everyone............

Shed the barriers of caste,
Humanity is so vast,
Unity is the keyword,
To a happy pleasant world,
Love everyone............

Like the starts that always shine
Make our life so pure divine,
HARDEV Lord, bless us all,
Rich and poor, big and small,
Love everyone............

* * * * *

Song 7

* HARMONY *

Harmony, Harmony
Let us all live in harmony, ("Harmony")
We are all members of family, ("Family")
Life is for living, Happily............
Harmony............

Let us all live together,
My dear sister my brother,
Let us cherish our - unity,
Sharing with one another,
All that we have to offer,
Respect each others identity
Vanity, Vanity,
Let us get rid of our vanity, ("Vanity")
Love all and shed all enmity, ("Enmity")
Lets us embrace Humanity.
Vanity............

This live is not forever,
Let us value this treasure,
Fill it with joy & not agony,
Come on dear friend, come closer,
Let us make life a pleasure,
This is the way that life ought to be,
Purity, Purity
Fill all our lives with Purity, ("Purity")
Pure thoughts and deeds with Sanctity, ("Sanctity")
Let us uphold Morality
Purity............

Thinking good for each other,
Doing good for each other
Living together in Amity,
Praying for one another,
Coming closer and closer,
Seeing the lord in all clarity,
Harmony, Harmony............

* * * * *

Song 8

* WELCOME BABA JI *

{We welcome you, with all our hearts,
We welcome you, You are the king of hearts,} - (2)
We welcome you, stay in our hearts,
O' lord Hardevji, You are welcome, welcome

Throughout the year, we all, keep waiting
So that You come, and shower Your blessings
Even a glimpse, of you, O' Messiah,
Gives us, new life, new hope, new meaning,
O" Lord Hardevji, bless us all, bless us.

Lord Master.. Thou Nirankar - (4)
Fill us O' Lord, with Your, sweet fragrance,
Brighten our lives, with Your, bright radiance,
Every moment, of our life, be with us
Fill all our minds, with Your, remembrance,
O" Lord Hardevji, bless us all, bless us.

We are your children, You our guardian,
We do make mistakes, and You pardon,
Guide us, that we too, forgive others,
Make us all noble, loving,  caring
O" Lord Hardevji, bless us all, bless us.
Lord Master.. Thou Nirankar - (4)

* * * * *

Song 9

* BLESS US WITH THY GRACE O' FATHER *

Bless us with thy grace o' Father, May we live in peace...
Love and Honor every human, Respect each other's needs
Bless us with thy grace O, Father..........

May we shed the barriers of caste, creed and race,
Omnipresent Lord let us see, YOU in every face,
May our worship bring us closer, distance decrease....(2)
Love & Honor..........

Hate and Envy, Lust and Greed are weaknesses O' Lord,
May we overcome these Vices, and be one with God,
Make us Humble, make us Noble, Virtuous in our deeds....(2)

Faith undaunted, Love limitless, Devotion pure and true,
Every moment of our life, we should remember YOU,
Follow YOUR teachings Messiah, do as You may please....(2)
Love & Honor..........

* * * * *

Song 10

* LET US PRAY TO THE GOD ALMIGHTY * 

{Let us pray to the God almighty
let us remember our Lord} - (2)
He is everywhere my dear,
Let us all live in love,

{We are here only for sometime,
Why this bloodshed O, friend of mine} - (2)

Life is a gift, pure and divine,
Let us all live in love,
Let us pray...

Let happiness be everywhere,
For each other, let us all care,
Love is gift, let us all share,
Let us all live in love,
Let us pray...

Everywhere see, God and HIS grace,
This world is, a wonderful place,
Let's put a smile, on every face,
Let us all live in love,
Let us pray......

* * * * *

Song 11

* LIVE AND LET OTHERS LIVE LIFE HAPPILY *

Live and let others, live life happily,
Live and let others, live in harmony,
Life is precious, gift of God, dear friend - (2)
Take good care of it, and use it properly,

Give others what, you want others, to give to you,
Forgive, forget, what is not good, and not for you,
True happiness, is in giving, and sharing joy,
Just try this out, and see my friend, what you can do
Try to cheer up, someone who, is woeful - (2)
A smile does not, cost even, a penny.
Live and let others.................

Remember that, the Lord is always, watching you
All that you say, all that you do, HE knows pf you
Every good deed, will be noted, rewarded too,
Love everyone, and everyone, will love you too,
God is in you, and in me, and all of us - (2)
We are members, of ONE big, Family.
Live and let others.................

* * * * *

Song 12

* LOVE MEN ONE AND ALL *

Love men One and all...(2)
Love men one and all, dear friends......
O' Children of One God, All of us are born
Love men one and all dear friend.

1)" No caste creed no race, See God in every face
No caste creed no race, dear friends...
O' children..................

2)" Universal brotherhood, Let us follow for good,
Universal brotherhood, dear friends....
O' children..................

3)" The MASTER shows the way, Let all of us obey
The MASTER shows the way, dear friends....
O' children..................

4)" HARDEV " the LORD Has come, To make all of us one
HARDEV the LORD has come, dear friends....
O' children of..................

* * * * *

`}`}</pre>
          </div>
        </div>
        <HeaderFooter />
      </div>
    </>
  );
}

export default Harmony;
