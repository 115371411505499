import React from "react";
import HeaderFooter from "./HeaderFooter";
import "./styles.css";

function SmileAndSing() {
  return (
    <>
      <div id="SmileAndSing" className="container-fluid mx-auto px-0">
        <div className="row mx-0">
          <div className="col-12 px-0">
            <pre className="text-center mb-0">{`${`

~ ~ ~ SMILE AND SING ~ ~ ~


Song 1

* LOVING FATHER *

Your are our loving,...o lord
For us you always bother,...o lord
You are loving our loving father, for us you always bother
Keep us always together ...o lord
You are our loving father. For us you always bother, keep us together ..o lord
You our loving father,..... 

Every moment, you care for us
All your bounties, your bare for us...(2)
Every ready to give, All our follies forgive, teaching us how to live...True master
You are our loving father,.... 

You have taught us to live and love,
Petty quarrels, To rise above...(2)
Bringing world unity, Instilling humanity, How to live in Harmony ...True Master
You are our loving father 

All we have is, But your grace
Yet we leave you, And shadows chase ....(2)
We forget to thank You, but you never, never do Add to give continue....
True master
You our loving father.....

Bless us father, Bless us all
With contentment patience and love......(2)
May we do what you say, live life divine Way, All they feet we all prey ...True Master
You are loving father ......

* * * * *

Song 2

* LOOK UP TO HIM *

Become like clay, And He will mould you
Look up to Him, And He will hold you.... 

Just go with flow, Become like a leaf
Enjoy the journey, Without strife and grief
Surrender to Him, And do no more
Let Him captain your ship, Take your to the shore
Become like clay, Him will mould you
Look up to Him, And He will hold you... 

Believe in yourself, Believe in Him
Lean not to demand, Learn to give in
Become His child, He will care for you
Gives more than you ask, Before you do
Become like clay, He will mould you
Look up to Him, And He will hold you... 

The deer gets caught, in the Web of deeds
The fruits of labour, The maze of need
Of faith and true, Just sow the seeds
You reap the harvest, He removes the mould you
Look up to Him, And He will hold you.....

* * * * *

Song 3

* BE WISE *

What you give is what you get
Such is the rule the Lord has set.
Let us be wise and share our joys.
Don’t give sorrows and joys expect.
What you give.... 

Love thy neighbor if you want love
Raise other, and you rise above
Lend a helping hand if you
Surely someone will held you
The world’s a mirror, says the sage/
All that you see is, your image
Let us be wise ....... 

Just shear a smile, and get a smile beak
Slap a guy, and you back a whack
Check direction before you walk
Think again friend, before you talk
It comes beak to you, what you say
Be careful before you choose the way.
Let be wise ......... 

Violence shall never bring peace
Hatred breaks us piece by piece
With the flowers there may be thorns
But the weeds just, what you sow
Burn a lamp and enjoy, all the glow
Let us wise.....

* * * * *

Song 4

* LIFE MOVES ON *

Day by day ,Like keeps moving on.
Day by day life keeps moving on,
Move ahead, with be let times
Or you will be left behind
Day by day.... 

Learn to live with change
For changes will stay 
What you see today will
Not remain this way, 
We have come, we shall go
Like rivers that flow
Moving forward, always day by day,
Day by day.... 

Leave not for tomorrow
What you can do today
Live life in the present
The future's for away.
Every second is chance
There's no second chance
This is the only way,
Day by day.... 

Find some time to
Where’s that time to hate
Patch up all differences
Before it is too late.
End hatred and gear
Spread goodwill and cheer
Before we our ways.
Day by day....... 

* * * * *

Song 5

* BE WHAT YOU ARE *

We say are human, do we live like one
Or hold a dove and the gun
We say are human do we live like one... 

A dog wage its tail, when it is happy
Expresses anger, when it gets snappy
But are we ready friendly, when we give a handshake
Or is vested interest, that is our stake.
Is life just a battle that has to be won
We say we are human do we live one... 

A lion will roar, and a cow will moo
A crow will caw, a cuckoo will coo
But are we ready genuine, when we shower our praise
Or is it only to please, and get a raise.
ls life just a race where we have to run
We say are human do we live like one.... 

Do we love values or, are they for sale
Do lies ready win, does truth ready fail
We want to achieve success, by hook or by crook
We ape and copy others, and forget our own look
Why don’t we be ourselves and enjoy the fun
We say human do we live one..

* * * * *

Song 6

* HIS GRACE *

His grace comes to us in so many ways
The sun is shining, Nirankar is smiling,
Just open your eyes and see the rays... 

The blooming of flowers, the buzzing of bees
The chirping of bird, the shade of the trees
The sun, the moon, and the stars that shine
Are they not gifts from the Lord Divine
Do we stop and say...Thank you, Thank you,
Come, let’s thank Him for all His grace
The sun is shining....

Our eyes that see, our touch that feels
Our heart that beat, our love that heals
The air we breath, the life we live 
All that we receive, all that we give
Is it not the Lord...... Himself, Himself
Within and without, with us always...
The sun is shining......... 

When we get what we want, He has blessed us sure
When he give us not, He has bless us more
He knows what is good, and best for us
But we know not, and make a fuss
For us He cares... Silently, Silently
Without any show, Without any trace...
The sun is shining.....

* * * * *

Song 7

* PEACE NOT PIECES *

Peace not pieces is what we need
Selfless service, no more of greed.
Come let’s rise above squabbles
One big family, we are indeed.

Let’s build bridges and not build walls
Take a step forward, not make just calls,
Of a global village, come sow the seed 
Pease not pieces is what we need.... 

We always talk of peace, let us walk toward it
A fabric of unity come on friend let us kint
On a common platform together let us sit 
Chip in come on friend, let us do our bit. 

Playing with fire will burn us all
Riding on shoulder, won‘t make us tall,
Lust and envy, shall hatred breed
Peace not pieces is what we need..... 

In you my reflection if I always do see
You and me both together shall it not become we
Like the leaves and the branches of one big tree
Compliment one another in perfect harmony. 

Unity will strengthen, division make us weak
If we are divided, our future is bleak,
Believe in oneness, not caste or creed
Peace not pieces is what we need.....

No sermons, no discovers galore
Let deeds do the talking, our actions to the fore
Violence and, terror, let's remove the core
Goodwill, cheer, and love, let’s flourish once more. 

Peace not pieces is what we need
Selfless service, no more of greed.
Come let’s rise above squabbles
One big family, we are indeed.

* * * * *

Song 8

* LOVE *

Love is the cure for all our ills
Love gives life and hatred kills
The temperatures are rising, the heat is on
The one who loves is the one who chills. 

We know the moon, but not our neighbor
To us is a stranger, the man next door
Running after without love, we are always poor
Love is the cure for all our ills... 

Love makes us human, or we animals
Without love, we shall become cannibals
Sharing and caring, is the essence of our life
Make the most of everything that is available.
Love is the cure for all ills.... 

The True Master love us, shows us how to love
To mend our fences, squabbles rise above
Enrich our existence, without spending a penny
Pool all our resource, into a treasure trove.
Love is the cure for all ills....

* * * * *

Song 9

* SURREDER *

May we accept what you give us
May we accept what you don’t,
Whatever you do, is always for us
The Best, the best, The best.
May we accept..... 

We get blinded, by our desires
Hanker after a mirage
We cannot see, true reality
Deception we wrongly applaud.
Ho to succeed, how do we pass
The Test, the Test, the Test.
May we accept..... 

All that we say, all that we do
ls but a bondage for all
We attain true liberty
If we listen to your call.
We do our best , and then we leave
May we accept ...... 

Give us strength to, change what we can
To accept all that, we can’t
To see the difference wisdom
To turn sorrow to joy.
Surrender to Thee, o Lord and be
The Blessed, the Blessed, The Blessed.
May we acoept.....

* * * * *

Song 10

* SMILE *

Smile today and smile tomorrow
Smile in work and play
Smile in joy and smile in sorrow
Smile your worries away.
Remember that, Remember that,
The Lord is with you everyday! 

Life is short and desires many
Time is slipping and fleeting away
Think not what you could not get, but
Remember all that you have got today.
The bounties of, the Almighty, 
Are countless if you see that wey.
Smile today and smile ........ 

Smile can turn strangers into friends,
Smile build bridges, bring us closer
Smiles can heal, and soothe and relieve us
Without a word make us feel better,
The magic of, the smile is a,
Miracle working day to day. 
Smile today and smile... 

Smile is positive, Smile is energy
Smile is moving close to God
Smile is saying thank you dear Lord
Smile is gratitude, to the dear Lord.
It is pious, it is holy, 
To smile is our prayer everyday.
Smile today and smile...

* * * * *

`}`}</pre>
          </div>
        </div>
        <HeaderFooter />
      </div>
    </>
  );
}

export default SmileAndSing;
