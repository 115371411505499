import React from "react";
import { NavLink } from "react-router-dom";
import HeaderFooter from "./HeaderFooter";
import "./styles.css";

const Navbar = () => {
  return (
    <>
      <div className="container-fluid text-center px-0 sticky-top ">
        <HeaderFooter />

        <div className="row mx-0 pb-4 rowbottom">
          <div className="col-4 px-0  ">
            <span className="">
              <NavLink
                className="navLinks p-1"
                to="/"
                draggable="false"
                title="Harmony"
              >
                Harmony
              </NavLink>
            </span>
          </div>
          <div className="col-4 px-0  ">
            <span className="">
              <NavLink
                className="navLinks p-1"
                to="/smileandsing"
                draggable="false"
                title="Smile & Sing"
              >
                Smile & Sing
              </NavLink>
            </span>
          </div>
          <div className="col-4 px-0  ">
            <span className="">
              <NavLink
                className="navLinks p-1"
                to="/songoflife"
                draggable="false"
                title="The Song Of Life"
              >
                Song Of Life
              </NavLink>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
