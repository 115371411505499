import React from "react";

function HeaderFooter() {
  return (
    <>
      <div className="container-fluid px-0 ">
        <div className="row mx-0 ">
          <div className="col-12 px-0 my-3">
            <p className="mb-0 py-2 logotext logorow">Sant Nirankari Mission</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderFooter;
