import React from "react";
import HeaderFooter from "./HeaderFooter";
import "./styles.css";

function SongOfLife() {
  return (
  <>
  
<div id="SongOfLife" className="container-fluid mx-auto px-0">
        <div className="row mx-0">
          <div className="col-12 px-0">
            <pre className="text-center mb-0">{`${`

~ ~ ~ THE SONG OF LIFE ~ ~ ~


Song 1

* THE SONG OF LIFE *

The gift of love, the song of life 
Let us all sing, let us enjoy 
Every moment, celebration 
Let’s thank the Lord , let us rejoice...
The gift of love ........... 

The Lord is always there for us 
And He always cares for us 
We may not know, He may not show
Yet He plans everything for us.. 
The gift of love, the song of life 
Let us all sing, let us enjoy 
The gift of love ........... 

He is in charge, just all the time
So everything’s gonna be fine
Leave it to Him, and He gives us
What is good, and at the right time 
The gift of love, the song of life 
Let us all sing, let us enjoy
The gift of love ........... 

No fears for now, no worries for now
All will be well, now we are sure
Just do your best, and leave the rest
His grace flows, and we shall glow
The gift of love, the song of life 
Let us all sing, let us enjoy 
The gift of love ........... 

* * * * *

Song 2

* THE SILVER LINING *

Every cloud has a silver lining
Behind the gloom the sun is shining.
Every cloud has a silver lining ..........

It’s not your day, they all do say
And things do not, just go your way
However this will also pass
The times so bad, are not to stay.
Every cloud has a silver lining ..........

From failures too, are lessons learnt
Every great chef, has fingers burnt
Better to try and fail my friend
Then not to try, and not get spurned.
Every cloud has a silver lining ..........

Why should I fear, the setting sun 
A brighter dawn, is yet to come
It’s not the end, O no my friend 
The race to win, has just begun.
Every cloud has a silver lining ..........    

Let winters pass, spring is waiting
Every sorrow, some joy’s dating
Just wait and see, the worries flee
Have faith and strength, the Lord’s awaiting.
Every cloud has a silver lining ..........  

* * * * *

Song 3

* LIVE TOGETHER LOVE TOGETHER *

Live together, love together
Share the bounties of the Lord
Wipe the tears and smile together
Spread love and joy thru the world 

The same water we all drink, and,
The same air we all do breathe
The same sun and moon for us all
The same is the earth beneath.
Live together .......... 

We are one if we believe so
Let there be no you and me
Every face is our reflection 
And part of us, our entity.
Live together ..........

There is enough for all of us
So why hatred and enmity 
If we just care to share with all
We can live so happily. 
Live together ..........

* * * * *

Song 4

* THIS IS THE TIME *

There is so much suffering that we see all around us
So much of hate, and lust, and greed within us
There is so much pollution, outside and inside too
Contamination, of each and every virtue. 

The more the darkness, the more there is the need for light
The sun is shining, don’t close your eyes and think it's night
Open your eyes, now, the Lord Master is smiling at us
Follow His footsteps, then everything shall be alright 

This is the time ..... to shape our future
This is the time if lost today, is lost forever
This is the time ..... it’s now or never 
This is the time if lost today, is lost forever
This is the time .....

No islands anymore ..... And no more shut doors
Let’s open our minds ... let neighbours indoors
Bounties everywhere, There is so much to share,
And so less to fight ... over 
This is the time ...... 

No winning of races .... No mask on faces
Just hearts to conquer ... no wars of places
The world’s beautiful, just so wonderful,
With no walls and no .... Borders 
This is the time ...... 

The power of love ...... the gift of this life
It's time to value ........ it’s time to revive
More valuable, than all the jewels, 
Is the bonding with each .... Other
This is the time ....... 

The more the darkness, the more there is the need for light
The sun is shining, don't close your eyes and think it's night
Open your eyes, now, the Lord Master is smiling at us
Follow His footsteps, and everything shall be alright

* * * * *

Song 5

* WHO IS TO BLAME *

Am I the one ...... who acted, but who never thought
Am I the one ...... who never acted only thought 
Am I the one ...... who only heard listened not 
Am I the one ...... who listened but always forgot
Who is to blame,... is it not only myself 
Who is to shame,... is it not me myself ? 
Am I the one ..................... 

Did I follow, ...... the teachings of the Messiah
Did I follow, ...... the preachings of the Messiah
Did I follow, ...... or only said that I want to 
Did I follow, ...... or only said that I meant to
Who is to blame,... is it not only myself 
Who is to shame,... is it not me myself ? 

All that I say ...... is it the truth, is it for good
All that I say ...... is it what I want understood
All that I say ...... is it soothing is it healing
All that i say ...... is it hurting others feelings
Who is to blame, is it not only myself 
Who is to shame, is it not me myself ?
Am I the one ..................... 

Are all my deeds ...... truly selfless, and truly pure 
Are all my deeds ...... well planned, not actions immature
Are all my deeds ...... worthy of me, the son of God 
Are all my deeds ...... surrendered to the Master Lord
Who is to blame, is it not only myself 
Who is to shame, is it not me myself ? 
Am I the one ..................... 

The love I seek ...... is it fleeting, or is it true
The love I seek ...... have I ever, given it too
The love I seek ...... the True Master is giving all
The love I seek ...... if l still do not find at all
Who is to blame, is it not only myself 
Who is to shame, is it not me myself ?
Am I the one ..................... 

* * * * *

Song 6

* WE ARE ONE FAMILY *

There is no you and me. Come closer, and see 
Wherever you go, It is all just we.
Many races. yet we are one
Many faces. yet we are one 
We the people of the wodd 
Are just one family 

The world is a global village 
And life is a pilgrimage 
Service to man is, service to the Lord
Love is true to worship, Love only is God.
Many races, yet we are one 
Many faces, yet we are one 
We the people of the world 
Are just one family 

Know One and then, believe in One
That is how, we can be One
Realize the self and, from bondage be free
Seek the True Master, and then rest easy
Many races, yet we are one
Many faces. yet we are one
We, the people of the world
Are just one family 

Peace not pieces, let's work for it
Love for all is our Mission
Worshippers we are. of this Nirankar
Only human, is all that we are
No ego, no vanity 
An ocean of humanity. 
We, the people of the world 
Are just one family 

There is no you and me,Come closer, and see
Wherever you go, It is all just we. 
Many races, yet we are one 
Many faces. yet we are one 
We the people of the world 
Are just one family 
We are one family ....
We are one family ........

* * * * *

Song 7

* AWARENESS *

I say you‘re always with me, am I really aware 
I always say I care for you, but do I really care 
I say you‘re ...............

When things go right. I praise myself. When they go wrong. I curse you
I feel I need you, only when, There are things, that I can't do. 
I know you‘re there, for me always, And yet I do, forget you 
I try and try, and try and try, Just everything, except you. 
I say I share my life with you, but do I really share 
I say you‘re ...............

I Iike to hide, my weaknesses, I like to show, I am the best
But others' strengths, and weaknesses, I always want to test
Their fIaws remain, on show for all, My fIaws no one, can see
In public eye, I always am, As good as good can be. 
I say you know everything Lord, but do I really care
I say you're ............... 

I hide my hate, my lust, my greed, Beneath a show, of love 
I pounce on prey, like an eagle, But call myself, a dove. 
My words are sweet, my deeds are not, I am clever and, I am sly
I forget that, you're watching me. Knowing all I do, and try.
Pardon I seek, but forgive not, everyday in my prayer 
I say you‘re ...............

* * * * *

Song 8

* TRUE FREEDOM *

I want to be free, I want to be free 
No bondage for me, I want to be free

No colour no face, no boundaries of place
No nations for me, I am a free race
No sects and no creed, no barriers of breed
Just humanity, I wish to embrace
I want to be free .................... 

I want to fly high, Rise up to the sky 
No roof and no walls, stop me when l fIy
No big and no small, no rich poor tall 
AII merge into one, if only I try 
I want to be free ....................

No dogmas for me, no rituals, no rites  
All places are same, no more sacred sites 
Religion no bar, my love is for all
My conscience dictates, the wrongs and the rights
I want to be free ....................

Know One, Be One, the Master has said
Peace and unity, the Master has bred
The freedom I have, is only His grace
I follow the path, the Master has tread
I am truly free, I am truly free 

No bondage for me, I am truly free
I am truly free ....................

* * * * *

Song 9

* SAVE THE EARTH *

Save the Earth... Save the Earth.....
Save the Earth... 

Nature is sending a warning to us 
Tsunamis, global warming 
The earth is turning barren day by day 
Acid rain is falling. 
More deserts less of trees, more numbers less species,
How can we rsit at ease ......... and relax 
Save the earth ........ 

Everywhere there is pollution
Our minds are no exception
Hatred and lust and ego 
So much contamination.
Save the earth......... 

Terror writ on our faces
Divided into races 
We say we are united 
No peace but only pieces.
Save the earth .......... 

This world needs you O Master
Come hold us now O Saviour
We are losing our identity
Redeem us O Redeemer. 
Save the earth ...........

* * * * *

`}`}</pre>
          </div>
        </div>
        <HeaderFooter />
      </div>
  
  </>);
}

export default SongOfLife;
